// @ts-nocheck
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  selectAccessToken,
  selectBuildingFilter,
  selectCToken,
  selectCompanyAndSiteSettings,
  selectDepartmentsFilter,
  selectFloorsFilter
} from "core/selectors";
import { selectSiteName } from "core/selectors/profileSelectors";
import { selectCompanyId } from "core/selectors/siteSelectors";

import { useAttendanceQuery, useUpdateDailyAssignmentMutation } from "features/attendance/attendanceApiSlice";
import { useSetOrUpdateEmployeeSettingMutation } from "features/userManagement/userManagementApiSlice";

import { fillEmployeesSchedules } from "pages/Attendance/helpers";

import { DAY_TYPES, MOMENT_DATE_FORMAT, WORK_DAYS_COUNT } from "shared/constants";
import { EEmployeeSettingName, ENonWorkingReason } from "shared/constants/settingConstants";

import currentWeekTableColumns from "../utils/currentWeekTableColumns";

export const START_DATE = moment().startOf("week").format(MOMENT_DATE_FORMAT);
export const END_DATE = moment().endOf("week").format(MOMENT_DATE_FORMAT);

const mapStateToProps = state => [
  selectAccessToken(state),
  selectCToken(state),
  selectCompanyAndSiteSettings(state),
  selectBuildingFilter(state),
  selectFloorsFilter(state),
  selectDepartmentsFilter(state),
  selectSiteName(state),
  selectCompanyId(state)
];

export default function useCurrentWeekTabLogic({ setSummary }) {
  /* ------------------ STATEs ------------------ */

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(currentWeekTableColumns(null));
  const [token, cToken, companyAndSiteSettings, building, floors, departments, siteName, companyId] =
    useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: attendanceData, isLoading } = useAttendanceQuery(
    {
      fromDate: START_DATE,
      toDate: END_DATE,
      building,
      departments,
      floors
    },
    { skip: !token }
  );

  const [updateDailyAssignment] = useUpdateDailyAssignmentMutation();
  const [setOrUpdateEmployeeSetting] = useSetOrUpdateEmployeeSettingMutation();

  /* ------------------ HANDLERs ------------------ */

  const handleChangeDayType = ({ dayType, employeeId, prev }, cb) => {
    Promise.allSettled([
      updateDailyAssignment({
        cToken,
        date: prev.date,
        employeeId,
        dayType,
        companyId,
        site: siteName,
        seatId: null
      }),

      setOrUpdateEmployeeSetting({
        cToken,
        employeeId,
        settingName: EEmployeeSettingName.nonWorkingReason,
        settingValue: dayType === DAY_TYPES.nonWorking ? ENonWorkingReason.none : ENonWorkingReason.armyService
      })
    ])
      .then(res => {
        const isSuccess = !res?.[0]?.error?.data;

        if (!isSuccess) {
          const errorMessage = res.error?.data?.details?.[0]?.errorMessage || "Failed to change day type";

          toast.error(errorMessage);
        } else {
          toast.success("Day type changed successfully");
        }

        cb?.(isSuccess);
      })
      .catch(e => {
        cb?.(false);

        toast.error("Failed to change day type");

        console.error(e);
      });
  };

  const setOfficeDaysColumnHeaders = data => {
    if (!data) return;

    const firstTableItem = data[0];

    setColumns(currentWeekTableColumns(firstTableItem, handleChangeDayType));
  };

  const getAttendanceHandler = () => {
    const data = fillEmployeesSchedules(attendanceData.data, {
      startDate: START_DATE,
      endDate: END_DATE,
      sundayFirst: companyAndSiteSettings.first_day_of_week === "Sunday",
      addWeekends: false
    });

    setOfficeDaysColumnHeaders(data);
    setRows(data);
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!attendanceData || isLoading) return;

    getAttendanceHandler();
  }, [attendanceData, isLoading]);

  useEffect(() => {
    setSummary({
      users: rows?.length,
      days: WORK_DAYS_COUNT
    });
  }, [rows?.length]);

  return {
    columns,
    rows,
    isLoading
  };
}
